<template>
    <form  class="">
        <div class="row mb-4 g-3">
            <div class="col-12 col-xl-8" >
               <h5 class="">Store type</h5>
            </div>
            <div class="col-md-12">
               <select v-model="data.store_type_id" name="store_type"
               class="form-select" :class="{ 'is-invalid': v$.data.store_type_id.$error  }">
                  <option value="" disabled>--store type--</option>
                  <option v-for="(sType, tIndex) in storeTypes"
                     :value="sType.id" :key="tIndex" > {{sType.title}} </option>
               </select>
               <div v-for="(item, index) in v$.data.store_type_id.$errors"
                  :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
               </div> 
            </div>
            <div class="col-12" >
               <hr >
            </div>
            <div class="col-12" >
               <h5 class="" >Property Information</h5>
            </div>
            <div class="col-12 col-lg-3 col-md-4" >
               <input v-model="data.title" class="form-control" id="title"
                  type="text"  placeholder="Name" name="title" :class="{ 'is-invalid': v$.data.title.$error }">
               <div v-for="(item, index) in v$.data.title.$errors"
                  :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
               </div> 
            </div>
            <div class="col-12 col-lg-3 col-md-4" >
               <input v-model="data.phone" v-maska data-maska="(###) ###-####" class="form-control" type="tel"
                  placeholder="Phone Number" :class="{ 'is-invalid': v$.data.phone.$error }" >
               <div v-for="(item, index) in v$.data.phone.$errors"
                  :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
               </div> 
            </div>
            <div class="col-12 col-lg-3 col-md-4" >
               <input v-model="data.email" class="form-control" :class="{ 'is-invalid': v$.data.email.$error }"
                id="email" type="text"  placeholder="Email" name="email" >
               <div v-for="(item, index) in v$.data.email.$errors" 
                  :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
               </div> 
            </div>
            <div class="col-12 col-lg-3 col-md-4" >
               <input v-model="data.website" class="form-control" :class="{ 'is-invalid': v$.data.website.$error }"
               id="website" type="text" :placeholder="`Website ${ isPhysicalStore ? '(Optional)':''}`" name="website" >
               <div v-for="(item, index) in v$.data.website.$errors" 
                  :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
               </div> 
            </div>
            <template v-if="isPhysicalStore">
               <div class="col-12" >
                  <div class="row py-3" >
                     <div class="col-12 col-lg-5 col-md-12" >
                        <label class="form-label hide-label-business" for="place" >Your business location
                        <br ><span class="text-muted fs-7" >Your location must have a Google Business listing. </span></label>
                     </div>
                     <div class="col-12 align-self-end col-lg-7 col-md-12" >
                        <div class="row g-3">
                           <div class="col-12">
                              <label for="streetAddress" class="form-label">Street address</label>
                              <input v-model="data.address" type="text" class="form-control"  name="address"
                              :class="{ 'is-invalid': v$.data.address.$error}"
                              id="streetAddress" placeholder="Street address">
                           </div>
                           <div class="col-12">
                              <label for="city" class="form-label">City</label>
                              <input v-model="data.city" type="text" class="form-control" name="city"
                              :class="{ 'is-invalid': v$.data.city.$error }"
                              id="city" placeholder="City">
                           </div>
                           <div class="col-md-6">
                              <label for="state" class="form-label">State</label>
                              <select v-model="data.state" class="form-select" name="state"
                              :class="{ 'is-invalid': v$.data.state.$error }"
                              aria-label="Select state">
                                 <option value="" disabled >Select state</option>
                                 <option v-for="(state, sIndex) in stateOptions" :key="sIndex"
                                       :value="state.value"> {{state.label}}</option>
                              </select>
                           </div>
                           <div class="col-md-6">
                              <label for="zip" class="form-label">Zip</label>
                              <input v-model="data.zip_code" type="number" class="form-control"  name="state"
                              :class="{ 'is-invalid': v$.data.zip_code.$error }"
                              id="zip" placeholder="Zip">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-12 mb-2" >
                  <hr >
               </div>
               <div class="col-12" >
                  <h5 class="" >Store Repair Details</h5>
               </div>
               <div class="col-12 col-xl-3 col-lg-6 col-md-6" >
                  <label class="form-label">Does this location offer repairs?</label>
                  <div class="row" >
                     <div class="col-6" >
                        <div class="form-check" >
                           <input v-model="data.provides_repairs" class="form-check-input"
                              id="provides_repairs_yes" type="radio" name="provides_repairs" :value="true"
                              ><label class="form-check-label" for="provides_repairs_yes"
                              >Yes</label>
                        </div>
                     </div>
                     <div class="col-6" >
                        <div class="form-check">
                           <input v-model="data.provides_repairs" class="form-check-input"
                           id="provides_repairs_no" type="radio" name="provides_repairs" :value="false"
                           ><label class="form-check-label" for="provides_repairs_no"
                           >No</label></div>
                     </div>
                  </div>
               </div>
               <div v-if="data.provides_repairs" class="col-12 col-xl-9 col-lg-6 col-md-6" >
                  <div class="row" >
                     <div class="col-12 col-lg-6 col-md-12">
                        <label class="form-label">What types of devices does this location repair?</label>
                        <div class="row">
                           <div v-for="(raDevice, raIndex) in categories" :key="raIndex" 
                              class="col-6 col-xxl-3 col-xl-4 col-md-4">
                              <div class="form-check">
                                 <input @change="checkSelection($event,raDevice)" v-model="data.repair_devices" :id="`repairs_apply_${raDevice.id}`"
                                  class="form-check-input"
                                 type="checkbox" name="repair_devices" :value="raDevice.id">
                                 <label :for="`repairs_apply_${raDevice.id}`">{{raDevice.title}}</label>
                              </div>
                           </div>
                           <div class="col-12 mb-2">
                              <input v-if="data.metadata.repair_devices_other" v-model="data.metadata.repair_devices_other_value" class="form-control py-1"
                               id="repair_devices_other_value"
                              type="text" placeholder="Please list other devices repaired" name="repair_devices_other_value">
                           </div>
                        </div>
                     </div>
                     <div v-if="data.repair_devices.length" class="col-12 col-lg-6 col-md-12">
                        <label class="form-label"> What is this location's approximate monthly repair volume per device type? </label>
                        <div class="row g-3">
                           <div v-for="(rbDevice, rbIndex) in selectedRepairDevices"
                              :key="rbIndex" class="col-6">
                              <div class="row g-2">
                                 <div class="col-5">
                                    <label class="form-label" :for="`repair_devices_volume_${rbDevice.id}`">{{rbDevice.title}}</label>
                                 </div>
                                 <div class="col-7">
                                    <input v-model="data.metadata[`repair_devices_volume_${rbDevice.id}`]" class="form-control py-1" :id="`repair_devices_volume_${rbDevice.id}`"
                                       type="text" placeholder="Units" :name="`repair_devices_volume_${rbDevice.id}`">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-12 mb-2" >
                  <hr >
               </div>
               <div class="col-12" >
                  <h5 class="pb-3" >Store Sales Details</h5>
               </div>
               <div class="col-12 col-xl-3 col-lg-6 col-md-6" >
                  <label class="form-label">Does this location sell devices?</label>
                  <div class="row" >
                     <div class="col-6" >
                        <div class="form-check" >
                           <input v-model="data.provides_sells" class="form-check-input"
                              id="provides_sells_yes" type="radio" name="provides_sells" :value="true"
                              ><label class="form-check-label" for="provides_sells_yes"
                              >Yes</label>
                        </div>
                     </div>
                     <div class="col-6" >
                        <div class="form-check">
                           <input v-model="data.provides_sells" class="form-check-input"
                           id="provides_sells_no" type="radio" name="provides_sells" :value="false"
                           ><label class="form-check-label" for="provides_sells_no"
                           >No</label></div>
                     </div>
                  </div>
               </div>
               <div v-if="data.provides_sells" class="col-12 col-xl-9 col-lg-6 col-md-6" >
                  <div class="row" >
                     <div class="col-12 col-lg-6 col-md-12">
                        <label class="form-label">Which devices does
                        this location sell?</label>
                        <div class="row">
                           <div v-for="(saDevice, saIndex) in categories" :key="saIndex" 
                              class="col-6 col-xxl-3 col-xl-4 col-md-4">
                              <div class="form-check">
                                 <input @change="checkSelection($event, saDevice)" v-model="data.sell_devices" :id="`sell_devices_apply_${saDevice.id}`" class="form-check-input"
                                 type="checkbox" name="sell_devices" :value="saDevice.id">
                                 <label :for="`sell_devices_apply_${saDevice.id}`">{{saDevice.title}}</label>
                              </div>
                           </div>
                           <div class="col-12 mb-2">
                               <input v-if="data.metadata.sell_devices_other" v-model="data.metadata.sell_devices_other_value" class="form-control py-1"
                               id="sell_devices_other_value"
                              type="text" placeholder="Please list other devices sold" name="sell_devices_other_value">
                           </div>
                        </div>
                     </div>
                     <div v-if="data.sell_devices.length" class="col-12 col-lg-6 col-md-12">
                        <label class="form-label"> What is this location's approximate sales volume per device type per month? </label>
                        <div class="row g-3">
                           <div v-for="(sbDevice, rbIndex) in selectedSellDevices"
                              :key="rbIndex" class="col-6">
                              <div class="row g-2">
                                 <div class="col-5">
                                    <label class="form-label" :for="`sell_devices_volume_${sbDevice.id}`">{{sbDevice.title}}</label>
                                 </div>
                                 <div class="col-7">
                                    <input v-model="data.metadata[`sell_devices_volume_${sbDevice.id}`]" class="form-control py-1" :id="`sell_devices_volume_${sbDevice.id}`"
                                       type="text" placeholder="Units" :name="`sell_devices_volume_${sbDevice.id}`">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </template>
         </div>
         <div class="row">
            <div class="col-12">
                <div class="d-flex mt-3">
                    <b-button variant="primary"  class="w-100 me-3"
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Create'}}</b-button>
                    <b-button variant="outline-primary" class="w-100"
                        @click.prevent="resetForm()"
                        type="button">Cancel</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, email, url, requiredIf,  helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
      editItem:{
         type: Object,
         default: null
      },
      editMode:{
         type: Boolean,
         default: false
      }
    },
    data(){
        return{
            data: {
               title: "",
               phone: "",
               email: "",
               website: "",
               address: "",
               state: "",
               city: "",
               zip_code: "",
               store_type_id: "",
               provides_repairs: false,
               provides_sells: false,
               repair_devices: [],
               sell_devices: [],
               place_details: {},
               metadata: {},
            },
        }
    },
    validations() {
      return {
         data:{
            store_type_id: {
               required: required,
            },
            title: {
               required: required,
            },
            phone: {
               required:required,
            },
            email: {
               required: required,
               email: helpers.withMessage("Please enter a valid email", email),
            },
            website: {
               requiredIf: requiredIf(!this.isPhysicalStore),
               url: url
            },
            address: {
               requiredIf: requiredIf(!!this.isPhysicalStore),
            },
            state: {
               requiredIf: requiredIf(!!this.isPhysicalStore),
            },
            city: {
               requiredIf: requiredIf(!!this.isPhysicalStore),
            },
            zip_code: {
               requiredIf: requiredIf(!!this.isPhysicalStore),
            },
         }
      }
    },
    computed:{
      isPhysicalStore(){
         const type = this.storeTypes.find(i => i.id == this.data.store_type_id)
         return type && type.required_key == 'physical_store'
      },
      categories(){
         return this.$store.state.categories
      },
      selectedSellDevices(){
         return this.categories.filter((rb) => this.data.sell_devices.includes(rb.id))
      },
      selectedRepairDevices(){
         return this.categories.filter((rb) => this.data.repair_devices.includes(rb.id))
      },
      storeTypes(){
         return this.$store.state.storeTypes
      },
      stateOptions(){
         return this.$store.state.stateOptions
      }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/stores/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("store/ADD_STORE", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/stores/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                           this.$emit('updatedData', response.data.data)
                           this.resetForm()
                        }
                    })
            })
        },
        checkSelection(e, input){
         if(e.target.checked){
            if(input.slug.includes("other")){
               this.data.metadata[`${e.target.name}_other`] = true
            }
         }else{
            if(input.slug.includes("other")){
              delete this.data.metadata[`${e.target.name}_other`]
            }
         }
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            const location = this.editItem.location || {}
            this.data = this.$filters.patchUpdatables(this.data, {...this.editItem, ...location})
            this.data.repair_devices = this.data.repair_devices.map(i => i.id);
            this.data.sell_devices = this.data.sell_devices.map(i => i.id);
        }
    }
}
</script>